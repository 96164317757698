module.exports = [{
      plugin: require('/home/circleci/repository/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/circleci/repository/node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://techuid.com"},
    },{
      plugin: require('/home/circleci/repository/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-144088430-1"},
    },{
      plugin: require('/home/circleci/repository/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":600},
    },{
      plugin: require('/home/circleci/repository/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
